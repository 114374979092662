import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"

const Page = (Perfeqta) => (

  <Layout>
    <div className="contentWrapper">

    
        <StaticImage 
        className="SplashImage"  
        src="../images/perfeqta.jpg" 
        alt="Perfeqta Software"/>
     
    

          <div className="casestudy">
            <h1>Perfeqta.io</h1>
            <dl>
              <dt>Role:</dt>
              <dd>Product Designer</dd>
       
              <dt>Company:</dt>
              <dd>Productive Technologies</dd>

              <dt>Scale:</dt>
              <dd>Responsive Web, Mobile and Tablet Devices</dd>
           
              <dt>Challenge:</dt>
              <dd>While technologically advanced in its algorithms and they were advanced! (Quality Assurance for blood banking is highly regulated, testing needs to be precise!), it lacked the components of a modern user interface to provide basic usability needs to users. As a result, users had to undergo multiple pieces of written training material to understand how to use the product and experience the full feature benefit.
              <br/>
              <br/>
              That benefit was to make deployments of digital processes easy for multiple highly regulated industries so they could achieve the efficiency that comes along with digital transformation.
              </dd>
            
              <dt>Process:</dt>
              <dd>I went through the written process users go through to learn and use the product so that I could have an understanding of their pain points. I met with stakeholders to understand the business strategy, apply basic UX/UI principles to the product, and market it to FDA, GxP, HIPAA, ISO, CLIA- regulated operations. </dd>
             
              <dt>Hypothesis:</dt>
              <dd>By creating a UI that encouraged task management and data visibility and made compliance processes easily accessible, companies such as hospitals, blood banks, and equipment manufacturers could increase compliance ratings. </dd>
          
              <dt>Results:</dt>
              <dd>PERFEQTA's client base has grown, and the software now services over 4,000 highly regulated processes.</dd>
            </dl>
          </div>

          <Footer/>
    </div>
   
 </Layout> 

)

export const Head = (Perfeqta) => <Seo title="Perfeqta" />

export default Page

